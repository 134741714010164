import { Socket } from 'socket.io-client';
import { HTMLAttributes, useEffect, useRef } from 'react';

import { DyteParticipantTile } from '@dytesdk/react-ui-kit';
import { PRESET_TYPE } from 'configs/meeting';
import useMeeting from 'hooks/useMeeting';
import { useMeetingStore } from 'lib/meeting-store';
import { NODE_ENV } from 'configs';
import { useStudentTalkTime } from 'hooks';
import { detectEmotionsFromStream } from 'utils';

export default function ActiveSpeaker(
  props: Omit<HTMLAttributes<HTMLDyteParticipantTileElement>, 'style'> & {
    isSmall?: true;
    // setDetectedEmotionsMap: React.Dispatch<React.SetStateAction<Record<string, any>>>;
    socket: Socket | undefined;
  },
) {
  const { isSmall, socket } = props;

  const processedStudentsRef = useRef(new Map());
  const { meeting, classId, joinedParticipants, socketUser, tutorId } = useMeeting();

  const [size, states] = useMeetingStore((s) => [s.size, s.states]);

  if (socketUser !== PRESET_TYPE.VIEWER) {
    useStudentTalkTime(socket);
  }

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { data } = event;
      if (data?.type === 'SEND_MESSAGE') {
        try {
          meeting.participants.broadcastMessage('tutorNudge', {
            data: event?.data?.payload?.data,
          });
        } catch (error) {
          console.error('Failed to send message:', error);
        }
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [tutorId]);

  useEffect(() => {
    if (socketUser !== PRESET_TYPE.VIEWER && NODE_ENV === 'prod') {
      joinedParticipants?.forEach((participant) => {
        if (participant?.videoTrack && !processedStudentsRef.current.has(participant.id)) {
          processedStudentsRef.current.set(participant.id, true);
          const userType = participant.presetName === 'group_call_host' ? 'tutor' : 'student';
          detectEmotionsFromStream(
            participant.videoTrack,
            // setDetectedEmotionsMap,
            socket,
            userType,
            participant.customParticipantId?.split('-').pop(),
            String(classId),
          );
        }
      });
    }
  }, [joinedParticipants]);

  return (
    <div className='w-full h-screen bg-gray-900'>
      <div className='w-full h-full p-2'>
        <div
          className={`grid w-full h-full gap-2 ${
            joinedParticipants?.length > 2 ? 'grid-cols-2' : 'grid-cols-1 place-items-center'
          }`}
        >
          {joinedParticipants?.map((participant) => (
            <div
              key={participant?.id + participant?.customParticipantId}
              className={`${joinedParticipants?.length === 1 ? 'w-full h-1/2' : 'w-full h-full'}`}
            >
              <DyteParticipantTile
                key={participant.id}
                participant={participant}
                meeting={meeting}
                size={isSmall ? 'lg' : size}
                states={states}
                {...props}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
                className='!w-full !h-full rounded-lg shadow-lg overflow-hidden'
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
