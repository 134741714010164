import { datadogLogs } from '@datadog/browser-logs';
import { Socket } from 'socket.io-client';

export async function detectEmotionsFromStream(
  videoStream: MediaStreamTrack,
  // setDetectedEmotionsMap: React.Dispatch<React.SetStateAction<Record<string, any>>>,
  socket: Socket | undefined,
  userType: string,
  userId?: string,
  classId?: string,
) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const video = document.createElement('video');
  video.autoplay = true;
  video.muted = true;

  if (!context) {
    console.error('Failed to get canvas context');
    return;
  }

  video.srcObject = new MediaStream([videoStream]);
  video.onloadedmetadata = () => {
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    video.play();
    const interval = setInterval(async () => {
      const istTime = new Date();
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imgData = context.getImageData(0, 0, canvas.width, canvas.height);
      const uint8ArrData = new Uint8Array(imgData.data);
      try {
        const detectedEmotions = await window.facialEye.test_emotion_image(
          uint8ArrData,
          canvas.width,
          canvas.height,
          true,
        );

        // Calculate additional fields
        detectedEmotions.confusion = detectedEmotions?.anger || 0;
        detectedEmotions.engagement =
          detectedEmotions?.anger +
            detectedEmotions?.contempt +
            detectedEmotions?.disgust +
            detectedEmotions?.fear +
            detectedEmotions?.happy +
            detectedEmotions?.neutral +
            detectedEmotions?.sadness +
            detectedEmotions?.surprise || 0;
        detectedEmotions.valence =
          (detectedEmotions?.confusion * -0.51 +
            detectedEmotions?.disgust * -0.6 +
            detectedEmotions?.fear * -0.64 +
            detectedEmotions?.happy * 0.81 +
            detectedEmotions?.sadness * -0.63 +
            detectedEmotions?.surprise * 0.4 +
            detectedEmotions?.neutral * 0.15 +
            detectedEmotions?.contempt * -0.55) /
          4.29;
        detectedEmotions.arousal =
          (detectedEmotions?.confusion * 0.59 +
            detectedEmotions?.disgust * 0.35 +
            detectedEmotions?.fear * 0.61 +
            detectedEmotions?.happy * 0.51 +
            detectedEmotions?.sadness * 0.29 +
            detectedEmotions?.surprise * 0.68 +
            detectedEmotions?.neutral * 0.15 +
            detectedEmotions?.contempt * 0.4) /
          3.58;

        // Add timestamp, studentId, and classId
        detectedEmotions.timestamp = istTime.toISOString();
        detectedEmotions.userId = Number(userId);
        detectedEmotions.classId = Number(classId);
        detectedEmotions.userType = userType;

        // Define the type for emotions
        type EmotionType = {
          anger: number;
          contempt: number;
          disgust: number;
          fear: number;
          happy: number;
          neutral: number;
          sadness: number;
          surprise: number;
        };

        const emotions: EmotionType = {
          anger: detectedEmotions?.anger,
          contempt: detectedEmotions?.contempt,
          disgust: detectedEmotions?.disgust,
          fear: detectedEmotions?.fear,
          happy: detectedEmotions?.happy,
          neutral: detectedEmotions?.neutral,
          sadness: detectedEmotions?.sadness,
          surprise: detectedEmotions?.surprise,
        };

        // Check if detectedEmotions has any emotion fields
        const hasEmotionFields = Object.keys(emotions).some(
          (key) => detectedEmotions[key as keyof EmotionType] !== undefined,
        );

        // Determine the dominant emotion or set to empty string
        if (hasEmotionFields) {
          const dominantEmotion = Object.keys(emotions).reduce((a, b) =>
            emotions[a as keyof EmotionType] > emotions[b as keyof EmotionType] ? a : b,
          );
          detectedEmotions.emotion = dominantEmotion;
        } else {
          detectedEmotions.emotion = '';
        }

        // Update the state with the latest detected emotions for the student
        // setDetectedEmotionsMap((prev) => ({
        //   ...prev,
        //   [userId!]: detectedEmotions,
        // }));

        if (socket) {
          socket?.emit(
            'emotionData',
            JSON.stringify({
              data: detectedEmotions,
            }),
          );
          return () => {
            socket?.removeAllListeners('message');
          };
        }
      } catch (error) {
        if (error instanceof Error && error?.message && error?.stack && typeof error !== 'number') {
          datadogLogs.logger.info(
            `Error detecting emotions: ${error.message}, stack: ${error.stack}`,
          );
        }
      }
    }, 1000);

    videoStream.addEventListener('ended', () => clearInterval(interval));
  };
}
