import { Socket } from 'socket.io-client';
import { useRef, useEffect, useMemo, MutableRefObject } from 'react';
import useMeeting from './useMeeting';
import { VAD_SOCKET_SERVER_URL } from '../configs/config';
import { datadogLogs } from '@datadog/browser-logs';
import { debounce } from 'lodash';

// Constants
const SAMPLE_RATE = 48000;
const BUFFER_SIZE = SAMPLE_RATE * 10; // 10 seconds of audio
const API_ENDPOINT = VAD_SOCKET_SERVER_URL || 'http://localhost:5000';
const BASE_RETRY_INTERVAL = 5000; // Starting with 5 seconds
const MAX_RETRY_INTERVAL = 60000; // Max 1 minute between retries
const MAX_RETRIES = 5;
const AUDIO_TRACK_RETRY_DELAY = 2000; // 2 seconds
const MAX_AUDIO_TRACK_RETRIES = 10;

// Add this to track retry attempts for audio tracks
const audioTrackRetryAttempts = new Map<string, number>();

// Track total retries per participant
const globalRetryAttempts = new Map<string, number>();

// Types
interface TalkTimeData {
  participantId: string;
  total_talk_time: number;
  total_session_duration: number;
  recent_talk_time: number;
  from_timestamp: number;
  to_timestamp: number;
  transcribed_speech: string;
}

interface AudioBuffer {
  data: Float32Array;
  position: number;
}

interface AudioTrackStatus {
  enabled: boolean;
  readyState: string;
  timestamp: number;
  lastError?: string;
}

interface Participant {
  presetName?: string;
  customParticipantId?: string;
  audioTrack?: MediaStreamTrack;
}

type AudioContextState = 'initializing' | 'active' | 'cleaning';

export const useStudentTalkTime = (socket: Socket | undefined) => {
  const { classId, joinedParticipants } = useMeeting();

  // Refs
  const audioContextRef = useRef<AudioContext | null>(null);
  const setupInProgressRef = useRef<boolean>(false);
  const initRef = useRef<boolean>(false);
  const audioContextStateRef = useRef<AudioContextState>('initializing');
  const audioBuffers = useRef<Map<string, AudioBuffer>>(new Map());
  const processingTimers = useRef<Map<string, NodeJS.Timeout>>(new Map());
  const isProcessing = useRef<Map<string, boolean>>(new Map());
  const processorNodes = useRef<Map<string, AudioWorkletNode>>(new Map());
  const processedParticipantsRef = useRef<Set<string>>(new Set());
  const activeProcessingRef = useRef<Set<string>>(new Set());
  const eventHandlersRef = useRef<
    Map<
      string,
      {
        ended: () => void;
        mute: () => void;
      }
    >
  >(new Map());
  const getDetailedAudioStatus = (
    participantId: string,
    audioTrack?: MediaStreamTrack,
    audioContext?: AudioContext,
  ) => {
    return {
      track: {
        exists: !!audioTrack,
        enabled: audioTrack?.enabled || false,
        readyState: audioTrack?.readyState || 'none',
        muted: audioTrack?.muted || false,
        constraints: audioTrack?.getConstraints() || {},
      },
      context: {
        exists: !!audioContext,
        state: audioContext?.state || 'closed',
        sampleRate: audioContext?.sampleRate || 0,
      },
      processing: {
        isProcessing: isProcessing.current.get(participantId) || false,
        hasProcessor: processorNodes.current.has(participantId),
        isActive: activeProcessingRef.current.has(participantId),
      },
    };
  };

  const isAudioTrackReady = (track: MediaStreamTrack | undefined): AudioTrackStatus => {
    const status: AudioTrackStatus = {
      enabled: false,
      readyState: 'none',
      timestamp: Date.now(),
    };

    if (!track) {
      status.lastError = 'No audio track available';
      return status;
    }

    status.enabled = track.enabled;
    status.readyState = track.readyState;

    if (!track.enabled) {
      status.lastError = 'Audio track is disabled';
      return status;
    }

    if (track.readyState !== 'live') {
      status.lastError = `Audio track not live (state: ${track.readyState})`;
      return status;
    }

    return status;
  };

  const getRetryInterval = (attempt: number): number => {
    const interval = BASE_RETRY_INTERVAL * Math.pow(2, attempt - 1);
    return Math.min(interval, MAX_RETRY_INTERVAL);
  };

  const initializeBuffer = (participantId: string) => {
    if (!audioBuffers.current.has(participantId)) {
      audioBuffers.current.set(participantId, {
        data: new Float32Array(BUFFER_SIZE),
        position: 0,
      });
    }
  };

  const processBufferImmediate = async (
    participantId: string,
    bufferData: Float32Array,
    participantNames: string[],
  ) => {
    // Declare requestId at the function scope level
    let requestId: string;

    try {
      isProcessing.current.set(participantId, true);

      const [userType, classIdPart, userId] = participantId.split('-');
      const timestamp = new Date().getTime().toString().slice(-9);
      requestId = `${userType}-${classIdPart}-${userId}-${timestamp}`;

      const payload = {
        audio_data: Array.from(bufferData),
        session_id: participantId,
        sample_rate: SAMPLE_RATE,
        user_type: userType,
        class_id: classIdPart,
        user_id: userId,
        participant_names: participantNames,
        request_id: requestId,
      };

      const response = await fetch(`${API_ENDPOINT}/process-audio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorResponse = await response.text();
        datadogLogs.logger.info(
          `Failed to process audio buffer: ${JSON.stringify({
            request_id: requestId,
            response_code: response.status,
            response_body: errorResponse,
            endpoint: API_ENDPOINT,
            event: 'audio_buffer_processing_failed',
          })}`,
        );
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorResponse}`);
      }

      const data: TalkTimeData = await response.json();

      if (socket?.connected) {
        socket.emit('talkTimeData', { data });
      }
    } catch (error) {
      datadogLogs.logger.info(
        `Failed to process audio buffer: ` +
          JSON.stringify({
            request_id: requestId!, // Using non-null assertion as we know it's defined
            error: error instanceof Error ? error.message : String(error),
            error_type: error instanceof Error ? error.name : 'Unknown',
          }),
      );
    } finally {
      isProcessing.current.set(participantId, false);
    }
  };

  const scheduleRetry = async (
    participantId: string,
    audioTrack: MediaStreamTrack,
    audioContext: AudioContext,
    classId: string,
  ) => {
    if (audioContextStateRef.current !== 'active') {
      return;
    }
    const currentAttempts = globalRetryAttempts.get(participantId) || 0;
    const nextAttempt = currentAttempts + 1;

    if (nextAttempt <= MAX_RETRIES) {
      const status = getDetailedAudioStatus(participantId, audioTrack, audioContext);

      // Don't retry if already processing successfully
      if (status.processing.isActive && status.track.enabled) {
        return;
      }

      if (!shouldAttemptRetry(participantId, audioTrack, nextAttempt)) {
        return;
      }

      // Attempt track recovery before scheduling retry
      const recovered = await attemptTrackRecovery(participantId, audioTrack, classId);
      if (recovered) {
        processAudioTrack(participantId, audioTrack, audioContext, classId);
        return;
      }

      const retryInterval = getRetryInterval(nextAttempt);

      globalRetryAttempts.set(participantId, nextAttempt);

      const retryTimeout = setTimeout(() => {
        const currentStatus = getDetailedAudioStatus(participantId, audioTrack, audioContext);
        if (
          currentStatus.track.enabled &&
          currentStatus.track.readyState === 'live' &&
          currentStatus.context.state === 'running'
        ) {
          processAudioTrack(participantId, audioTrack, audioContext, classId);
        } else {
          scheduleRetry(participantId, audioTrack, audioContext, classId);
        }
      }, retryInterval);

      processingTimers.current.set(participantId, retryTimeout);
    } else {
      globalRetryAttempts.delete(participantId);
    }
  };

  const debouncedTrackStateChange = debounce((participantId, audioTrack, audioContext, classId) => {
    if (!audioTrack.enabled) {
      scheduleRetry(participantId, audioTrack, audioContext, classId);
    }
  }, 1000);

  const attemptTrackRecovery = async (
    participantId: string,
    audioTrack: MediaStreamTrack,
    classId: string,
  ): Promise<boolean> => {
    try {
      await audioTrack.clone().getSettings(); // Attempt to clone track
      if (!audioTrack.enabled) {
        audioTrack.enabled = true;
        return true;
      }
    } catch (error) {
      datadogLogs.logger.info(
        `Track recovery failed: ${JSON.stringify({
          participantId,
          classId,
          error: error instanceof Error ? error.message : String(error),
          event: 'track_recovery_failed',
        })}`,
      );
    }
    return false;
  };

  const trackDisableTimestamp = new Map<string, number>();

  const retryAudioTrackAcquisition = (
    participant: Participant,
    classId: string,
    audioContextRef: React.RefObject<AudioContext>,
    processedParticipantsRef: MutableRefObject<Set<string>>,
  ) => {
    if (audioContextStateRef.current !== 'active') {
      return;
    }
    const { customParticipantId, presetName, audioTrack } = participant;

    if (customParticipantId && audioTrack) {
      datadogLogs.logger.info(
        'Audio track state before retry ' +
          JSON.stringify({
            participantId: customParticipantId,
            state: {
              enabled: audioTrack.enabled,
              readyState: audioTrack.readyState,
              muted: audioTrack.muted,
            },
            classId,
            event: 'audio_track_retry_state',
          }),
      );
    }

    // Early return if no customParticipantId
    if (!customParticipantId) {
      datadogLogs.logger.info(
        'Missing participant ID during retry attempt ' +
          JSON.stringify({
            participant,
            classId,
            event: 'retry_failed_missing_id',
          }),
      );
      return;
    }

    const retryCount = audioTrackRetryAttempts.get(customParticipantId) || 0;
    const delay = Math.min(AUDIO_TRACK_RETRY_DELAY * Math.pow(2, retryCount), 30000); // Max 30 second delay

    if (retryCount >= MAX_AUDIO_TRACK_RETRIES) {
      datadogLogs.logger.info(
        'Max retry attempts reached for audio track acquisition ' +
          JSON.stringify({
            participantId: customParticipantId,
            participantType: presetName,
            retryCount,
            maxRetries: MAX_AUDIO_TRACK_RETRIES,
            classId,
            event: 'max_retries_reached',
          }),
      );
      audioTrackRetryAttempts.delete(customParticipantId);
      return;
    }

    // Log the retry attempt
    datadogLogs.logger.info(
      'Attempting audio track acquisition ' +
        JSON.stringify({
          participantId: customParticipantId,
          participantType: presetName,
          retryCount,
          nextRetryDelay: delay,
          classId,
          event: 'audio_track_acquisition_attempt',
        }),
    );

    setTimeout(async () => {
      try {
        // Get fresh participant data
        const updatedParticipant = joinedParticipants.find(
          (p) => p.customParticipantId === customParticipantId,
        );

        if (!updatedParticipant) {
          datadogLogs.logger.info(
            'Participant no longer present ' +
              JSON.stringify({
                participantId: customParticipantId,
                classId,
                event: 'participant_departed',
              }),
          );
          audioTrackRetryAttempts.delete(customParticipantId);
          return;
        }

        // Log the participant's current state
        datadogLogs.logger.info(
          'Current participant state ' +
            JSON.stringify({
              participantId: customParticipantId,
              hasAudioTrack: !!updatedParticipant.audioTrack,
              audioTrackState: updatedParticipant.audioTrack
                ? {
                    enabled: updatedParticipant.audioTrack.enabled,
                    readyState: updatedParticipant.audioTrack.readyState,
                    muted: updatedParticipant.audioTrack.muted,
                  }
                : null,
              retryAttempt: retryCount + 1,
              classId,
              event: 'participant_state_check',
            }),
        );

        if (updatedParticipant.audioTrack) {
          // Verify the audio track is actually usable
          const trackStatus = isAudioTrackReady(updatedParticipant.audioTrack);

          if (!trackStatus.lastError) {
            datadogLogs.logger.info(
              'Audio track acquired successfully ' +
                JSON.stringify({
                  participantId: customParticipantId,
                  retryCount,
                  classId,
                  trackStatus,
                  event: 'audio_track_acquired',
                }),
            );

            audioTrackRetryAttempts.delete(customParticipantId);
            processParticipants(
              [updatedParticipant],
              classId,
              audioContextRef,
              processedParticipantsRef,
            );
          } else {
            // Track exists but isn't ready, increment retry and try again
            datadogLogs.logger.info(
              'Audio track exists but not ready ' +
                JSON.stringify({
                  participantId: customParticipantId,
                  error: trackStatus.lastError,
                  retryCount,
                  classId,
                  event: 'audio_track_not_ready',
                }),
            );

            audioTrackRetryAttempts.set(customParticipantId, retryCount + 1);
            retryAudioTrackAcquisition(
              updatedParticipant,
              classId,
              audioContextRef,
              processedParticipantsRef,
            );
          }
        } else {
          // No audio track yet, increment retry and try again
          audioTrackRetryAttempts.set(customParticipantId, retryCount + 1);
          datadogLogs.logger.info(
            'Audio track not available, retrying ' +
              JSON.stringify({
                participantId: customParticipantId,
                nextRetryCount: retryCount + 1,
                nextDelay: delay,
                classId,
                event: 'audio_track_retry',
              }),
          );

          retryAudioTrackAcquisition(
            updatedParticipant,
            classId,
            audioContextRef,
            processedParticipantsRef,
          );
        }
      } catch (error) {
        datadogLogs.logger.info(
          'Error during audio track acquisition ' +
            JSON.stringify({
              participantId: customParticipantId,
              error: error instanceof Error ? error.message : String(error),
              retryCount,
              classId,
              event: 'audio_track_acquisition_error',
            }),
        );

        // Still increment retry count on error
        audioTrackRetryAttempts.set(customParticipantId, retryCount + 1);
        retryAudioTrackAcquisition(participant, classId, audioContextRef, processedParticipantsRef);
      }
    }, delay);
  };

  const shouldAttemptRetry = (
    participantId: string,
    audioTrack: MediaStreamTrack,
    currentAttempts: number,
  ): boolean => {
    if (currentAttempts >= MAX_RETRIES) return false;

    // Add grace period for track recovery
    const lastDisableTime = trackDisableTimestamp.get(participantId) || 0;
    if (Date.now() - lastDisableTime < 2000) {
      return false;
    }

    const trackStatus = isAudioTrackReady(audioTrack);
    if (!trackStatus.enabled) {
      trackDisableTimestamp.set(participantId, Date.now());
      return false;
    }

    return true;
  };

  interface HealthCheckMetrics {
    silentFrames: number;
    totalFrames: number;
    lastCheckTime: number;
    enabled: boolean;
  }

  // Modify the processAudioTrack function to integrate health checks
  const processAudioTrack = (
    participantId: string,
    audioTrack: MediaStreamTrack,
    audioContext: AudioContext,
    classId: string,
  ) => {
    try {
      if (audioContextStateRef.current !== 'active') {
        return;
      }
      const status = getDetailedAudioStatus(participantId, audioTrack, audioContext);

      // Don't process if already active
      if (status.processing.isActive) {
        return;
      }

      // Verify audio track is ready
      if (!audioTrack.enabled || audioTrack.readyState !== 'live') {
        throw new Error(
          `Audio track not ready - enabled: ${audioTrack.enabled}, state: ${audioTrack.readyState}`,
        );
      }

      // Clean up any existing processor
      const existingProcessor = processorNodes.current.get(participantId);
      if (existingProcessor) {
        existingProcessor.disconnect();
        existingProcessor.port.close();
        processorNodes.current.delete(participantId);
      }

      // Initialize processing
      initializeBuffer(participantId);
      isProcessing.current.set(participantId, true);

      const mediaStream = new MediaStream([audioTrack]);
      const source = audioContext.createMediaStreamSource(mediaStream);

      const endedHandler = () => {
        debouncedTrackStateChange(participantId, audioTrack, audioContext, classId);
      };
      const muteHandler = () => {
        debouncedTrackStateChange(participantId, audioTrack, audioContext, classId);
      };

      audioTrack.addEventListener('ended', endedHandler);
      audioTrack.addEventListener('mute', muteHandler);

      eventHandlersRef.current.set(participantId, {
        ended: endedHandler,
        mute: muteHandler,
      });
      const processor = new AudioWorkletNode(audioContext, 'audio-processor', {
        numberOfInputs: 1,
        numberOfOutputs: 1,
        channelCount: 1,
        channelCountMode: 'explicit',
        channelInterpretation: 'discrete',
        processorOptions: {
          sampleRate: SAMPLE_RATE,
        },
      });

      // Initialize health check metrics
      const metrics: HealthCheckMetrics = {
        silentFrames: 0,
        totalFrames: 0,
        lastCheckTime: Date.now(),
        enabled: true,
      };

      // Set up health check monitoring
      const healthCheckInterval = setInterval(() => {
        if (!metrics.enabled) return;

        const currentTime = Date.now();
        const timeSinceLastCheck = currentTime - metrics.lastCheckTime;

        if (
          !audioTrack.enabled ||
          audioTrack.readyState !== 'live' ||
          audioContext.state !== 'running'
        ) {
          datadogLogs.logger.info(
            'Audio health check failed ' +
              JSON.stringify({
                classId,
                participantId,
                trackStatus: {
                  enabled: audioTrack.enabled,
                  readyState: audioTrack.readyState,
                  contextState: audioContext.state,
                },
                metrics: {
                  silentFrames: metrics.silentFrames,
                  totalFrames: metrics.totalFrames,
                  silentRatio:
                    metrics.totalFrames > 0 ? metrics.silentFrames / metrics.totalFrames : 0,
                  timeSinceLastCheck,
                },
                event: 'audio_health_check_failed',
              }),
          );

          metrics.enabled = false;
          clearInterval(healthCheckInterval);
          scheduleRetry(participantId, audioTrack, audioContext, classId);
        }

        // Reset metrics periodically
        metrics.silentFrames = 0;
        metrics.totalFrames = 0;
        metrics.lastCheckTime = currentTime;
      }, 10000);

      // Store the health check interval for cleanup
      processingTimers.current.set(
        `health_${participantId}`,
        healthCheckInterval as unknown as NodeJS.Timeout,
      );

      let hasReceivedData = false;
      processor.port.onmessage = (event: MessageEvent) => {
        const audioData = event.data;
        if (!Array.isArray(audioData) && !(audioData instanceof Float32Array)) {
          datadogLogs.logger.info(
            'Invalid audio data received ' +
              JSON.stringify({
                participantId,
                classId,
                dataType: typeof audioData,
                event: 'invalid_audio_data',
              }),
          );
          return;
        }

        if (!hasReceivedData) {
          hasReceivedData = true;
          activeProcessingRef.current.add(participantId);
        }

        // Update health check metrics
        if (audioData instanceof Float32Array) {
          metrics.totalFrames++;
          const isFrameSilent = audioData.every((sample) => Math.abs(sample) < 0.01);
          if (isFrameSilent) {
            metrics.silentFrames++;
          }
        }

        const float32Data =
          audioData instanceof Float32Array ? audioData : new Float32Array(audioData);
        void processBufferImmediate(participantId, float32Data, validParticipantNames);
      };

      source.connect(processor);
      processorNodes.current.set(participantId, processor);

      globalRetryAttempts.delete(participantId);
      processedParticipantsRef.current.add(participantId);
    } catch (error) {
      datadogLogs.logger.info(
        'Failed to process audio' +
          JSON.stringify({
            participantId,
            classId,
            error: error instanceof Error ? error.message : String(error),
            status: getDetailedAudioStatus(participantId, audioTrack, audioContext),
            event: 'audio_processing_failed',
          }),
      );
      scheduleRetry(participantId, audioTrack, audioContext, classId);
    }
  };

  const processParticipants = (
    participants: Participant[],
    classId: string,
    audioContextRef: React.RefObject<AudioContext>,
    processedParticipantsRef: MutableRefObject<Set<string>>,
  ) => {
    if (setupInProgressRef.current || audioContextStateRef.current !== 'active') {
      datadogLogs.logger.info(
        'Skipping participant processing ' +
          JSON.stringify({
            state: audioContextStateRef.current,
            setupInProgress: setupInProgressRef.current,
            classId,
            event: 'processing_skipped',
          }),
      );
      return;
    }
    // Log incoming participants for debugging
    datadogLogs.logger.info(
      `Processing participants: ${JSON.stringify({
        totalCount: participants.length,
        participants: participants.map((p) => ({
          id: p.customParticipantId,
          type: p.presetName,
          hasAudio: !!p.audioTrack,
          classId,
        })),
        event: 'processing_participants',
      })}`,
    );

    participants.forEach((participant) => {
      const { presetName, customParticipantId, audioTrack } = participant;

      if (!customParticipantId) {
        datadogLogs.logger.info(
          `Missing participant ID: ${JSON.stringify({
            classId,
            event: 'missing_participant_id',
          })}`,
        );
        return;
      }

      if (!presetName) {
        datadogLogs.logger.info(
          `Missing preset name for participant: ` +
            JSON.stringify({
              participantId: customParticipantId,
              classId: classId,
            }),
        );
        return;
      }

      if (!audioTrack) {
        retryAudioTrackAcquisition(participant, classId, audioContextRef, processedParticipantsRef);
        return;
      }

      const userType = presetName === 'group_call_host' ? 'tutor' : 'student';
      const participantId = `${userType}-${classId}-${customParticipantId.split('-').pop()}`;
      datadogLogs.logger.info(
        `Processing participant: ${JSON.stringify({
          presetName,
          customParticipantId,
          hasAudioTrack: !!audioTrack,
          expectedTutorName: 'group_call_host',
          isHost: presetName === 'group_call_host',
          classId: classId,
        })}`,
      );
      if (processedParticipantsRef.current.has(participantId)) {
        return;
      }

      if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
        return;
      }

      const trackStatus = isAudioTrackReady(audioTrack);

      if (!trackStatus.lastError && audioContextRef.current) {
        // Inside processParticipants, before processAudioTrack call:
        if (presetName === 'group_call_host') {
          datadogLogs.logger.info(
            'Processing tutor audio ' +
              JSON.stringify({
                participantId,
                hasAudioTrack: !!audioTrack,
                audioTrackState: {
                  enabled: audioTrack.enabled,
                  readyState: audioTrack.readyState,
                },
                classId,
                event: 'tutor_audio_processing',
              }),
          );
        }
        processAudioTrack(participantId, audioTrack, audioContextRef.current, classId);
      } else {
        if (audioContextRef.current) {
          scheduleRetry(participantId, audioTrack, audioContextRef.current, classId);
        }
      }
    });

    // Log processed participants after completion
    datadogLogs.logger.info(
      'Processed participants summary: ' +
        JSON.stringify({
          totalProcessed: processedParticipantsRef.current.size,
          processedIds: Array.from(processedParticipantsRef.current),
          classId: classId,
        }),
    );
  };

  const participantIds = useMemo(
    () =>
      joinedParticipants
        .filter((participant) => participant.presetName && participant.customParticipantId)
        .map((participant) => {
          const userType = participant.presetName === 'group_call_host' ? 'tutor' : 'student';
          return `${userType}-${classId}-${participant.customParticipantId?.split('-').pop()}`;
        }),
    [joinedParticipants, classId],
  );
  const participantNames = joinedParticipants.map((participant) => participant.name);
  const validParticipantNames = participantNames.filter(
    (name): name is string => name !== undefined,
  );
  type CleanupReason = 'NO_PARTICIPANTS' | 'STATE_TRANSITION' | 'RECORDING_STOP' | 'FORCED';

  const cleanupAudioProcessing = (reason: CleanupReason = 'FORCED') => {
    // Don't cleanup if we have active participants unless forced
    if (reason !== 'FORCED' && joinedParticipants.length > 0) {
      datadogLogs.logger.info(
        'Cleanup prevented ' +
          JSON.stringify({
            classId,
            reason,
            participantCount: joinedParticipants.length,
            event: 'cleanup_prevented',
          }),
      );
      return;
    }

    if (audioContextStateRef.current === 'cleaning') {
      datadogLogs.logger.info(
        'Cleanup already in progress ' +
          JSON.stringify({
            classId,
            reason,
            event: 'cleanup_skipped',
          }),
      );
      return;
    }

    datadogLogs.logger.info(
      'Starting audio cleanup ' +
        JSON.stringify({
          classId,
          reason,
          currentState: audioContextStateRef.current,
          processedParticipants: processedParticipantsRef.current.size,
          event: 'cleanup_started',
        }),
    );

    audioContextStateRef.current = 'cleaning';
    activeProcessingRef.current.clear();

    // Track cleanup completion
    let cleanupComplete = false;

    try {
      joinedParticipants.forEach((participant) => {
        if (participant.audioTrack) {
          participant.audioTrack.removeEventListener('ended', () => {});
          participant.audioTrack.removeEventListener('mute', () => {});
          participant.audioTrack.removeEventListener('unmute', () => {});
        }
      });

      // Clear all processing timers (including health checks)
      processingTimers.current.forEach((timer) => {
        clearTimeout(timer);
        clearInterval(timer);
      });
      processingTimers.current.clear();

      // Cleanup processor nodes
      processorNodes.current.forEach((node, participantId) => {
        try {
          node.disconnect();
          node.port.close();
        } catch (error) {
          datadogLogs.logger.info(
            'Error cleaning up processor node ' +
              JSON.stringify({
                participantId,
                classId,
                error: error instanceof Error ? error.message : String(error),
                event: 'processor_cleanup_error',
              }),
          );
        }
      });

      // Close AudioContext
      if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
        void audioContextRef.current
          .close()
          .then(() => {
            datadogLogs.logger.info(
              'AudioContext closed successfully ' +
                JSON.stringify({
                  classId,
                  event: 'audio_context_closed',
                }),
            );
          })
          .catch((error) => {
            datadogLogs.logger.info(
              'Failed to close AudioContext ' +
                JSON.stringify({
                  classId,
                  error: error instanceof Error ? error.message : String(error),
                  event: 'audio_context_close_failed',
                }),
            );
          });
      }

      // Clear all state
      processorNodes.current.clear();
      audioBuffers.current.clear();
      isProcessing.current.clear();
      processedParticipantsRef.current.clear();
      audioTrackRetryAttempts.clear();
      trackDisableTimestamp.clear();

      // Clear retry attempts for departed participants
      const currentParticipantIds = new Set(participantIds);
      for (const [participantId] of globalRetryAttempts) {
        if (!currentParticipantIds.has(participantId)) {
          globalRetryAttempts.delete(participantId);
        }
      }

      cleanupComplete = true;
    } catch (error) {
      datadogLogs.logger.info(
        'Error during cleanup ' +
          JSON.stringify({
            classId,
            error: error instanceof Error ? error.message : String(error),
            event: 'cleanup_error',
          }),
      );
    } finally {
      datadogLogs.logger.info(
        'Cleanup completed ' +
          JSON.stringify({
            classId,
            success: cleanupComplete,
            event: 'cleanup_completed',
          }),
      );
    }
  };

  const latestParticipantCountRef = useRef(joinedParticipants.length);

  // Update on each render
  useEffect(() => {
    latestParticipantCountRef.current = joinedParticipants.length;
  }, [joinedParticipants.length]);

  const debouncedCleanup = useMemo(
    () =>
      debounce(() => {
        // Use the ref to get the latest count
        if (latestParticipantCountRef.current === 0) {
          datadogLogs.logger.info(
            'Executing cleanup - no participants ' +
              JSON.stringify({
                classId,
                currentState: audioContextStateRef.current,
                participantCount: latestParticipantCountRef.current,
                event: 'cleanup_executing',
              }),
          );
          cleanupAudioProcessing('NO_PARTICIPANTS');
        } else {
          datadogLogs.logger.info(
            'Cleanup cancelled - participants present ' +
              JSON.stringify({
                classId,
                currentState: audioContextStateRef.current,
                participantCount: latestParticipantCountRef.current,
                event: 'cleanup_cancelled',
              }),
          );
        }
      }, 2000),
    [], // No dependencies needed since we use ref
  );

  useEffect(() => {
    const RECOVERY_TIMEOUT = 5000;
    let recoveryTimeout: NodeJS.Timeout;

    if (audioContextStateRef.current === 'cleaning' && joinedParticipants.length > 0) {
      // Check if we really need recovery
      const needsRecovery =
        !audioContextRef.current ||
        audioContextRef.current.state === 'closed' ||
        processedParticipantsRef.current.size === 0;

      // Also check if this was a forced cleanup
      const wasForced =
        processedParticipantsRef.current.size > 0 && audioContextRef.current?.state === 'closed';

      if (needsRecovery && wasForced) {
        datadogLogs.logger.info(
          'Planning state recovery ' +
            JSON.stringify({
              participantCount: joinedParticipants.length,
              classId,
              audioContextState: audioContextRef.current?.state || 'none',
              processedParticipants: processedParticipantsRef.current.size,
              wasForced,
              event: 'state_recovery_planned',
            }),
        );

        recoveryTimeout = setTimeout(() => {
          // Double check state hasn't changed during timeout
          if (audioContextStateRef.current === 'cleaning' && needsRecovery) {
            datadogLogs.logger.info(
              'Executing state recovery ' +
                JSON.stringify({
                  participantCount: joinedParticipants.length,
                  classId,
                  audioContextState: audioContextRef.current?.state || 'none',
                  processedParticipants: processedParticipantsRef.current.size,
                  wasForced,
                  event: 'state_recovery_executing',
                }),
            );

            audioContextStateRef.current = 'initializing';
            setupAudio();
          }
        }, RECOVERY_TIMEOUT);
      }
    }

    return () => {
      if (recoveryTimeout) {
        clearTimeout(recoveryTimeout);
      }
    };
  }, [audioContextStateRef.current, joinedParticipants.length]);

  type AudioSetupError = {
    message: string;
    code: 'TIMEOUT' | 'STATE_ERROR' | 'WORKLET_ERROR' | 'UNKNOWN';
    details?: unknown;
  };

  const setupAudio = async () => {
    if (setupInProgressRef.current) {
      // Keep this simple check
      datadogLogs.logger.info(
        'Setup already in progress: ' +
          JSON.stringify({
            classId,
            state: audioContextStateRef.current,
            event: 'setup_skipped',
          }),
      );
      return;
    }

    try {
      setupInProgressRef.current = true;
      audioContextStateRef.current = 'initializing';

      // Create or get AudioContext with proper typing
      const createAudioContext = async (): Promise<AudioContext> => {
        if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
          audioContextRef.current = new window.AudioContext({ sampleRate: SAMPLE_RATE });
        }
        return audioContextRef.current;
      };

      // Type-safe timeout wrapper
      const withTimeout = <T>(
        promise: Promise<T>,
        ms: number,
        errorMessage: string,
      ): Promise<T> => {
        return Promise.race([
          promise,
          new Promise<T>((_, reject) =>
            setTimeout(
              () =>
                reject({
                  message: errorMessage,
                  code: 'TIMEOUT' as const,
                }),
              ms,
            ),
          ),
        ]);
      };

      // Create context with timeout
      const audioContext = await withTimeout(
        createAudioContext(),
        5000,
        'AudioContext creation timeout',
      );

      // Resume context if needed
      if (audioContext.state === 'suspended') {
        await withTimeout(audioContext.resume(), 3000, 'AudioContext resume timeout');
      }

      // Load audio worklet
      await withTimeout(
        audioContext.audioWorklet.addModule('/audio-processor.js'),
        5000,
        'AudioWorklet module load timeout',
      );

      if (audioContext.state === 'running') {
        audioContextStateRef.current = 'active';
        setupInProgressRef.current = false;
        datadogLogs.logger.info(
          'Audio setup completed: ' +
            JSON.stringify({
              classId,
              contextState: audioContext.state,
              sampleRate: audioContext.sampleRate,
              event: 'audio_setup_success',
            }),
        );
        processParticipants(joinedParticipants, classId, audioContextRef, processedParticipantsRef);
      } else {
        throw {
          message: `Unexpected AudioContext state: ${audioContext.state}`,
          code: 'STATE_ERROR' as const,
        };
      }
    } catch (error) {
      audioContextStateRef.current = 'initializing';
      const typedError = error as AudioSetupError;

      datadogLogs.logger.info(
        'Audio setup failed: ' +
          JSON.stringify({
            classId,
            error: typedError.message,
            code: typedError.code || 'UNKNOWN',
            details: typedError.details,
            event: 'audio_setup_failed',
          }),
      );

      // Add retry mechanism for setup failures
      setTimeout(setupAudio, 5000);
    }
  };

  useEffect(() => {
    if (joinedParticipants.length === 0) {
      datadogLogs.logger.info(
        'Participant count zero detected ' +
          JSON.stringify({
            classId,
            currentState: audioContextStateRef.current,
            event: 'zero_participants_detected',
          }),
      );
      // Schedule cleanup
      debouncedCleanup();
      return;
    }

    datadogLogs.logger.info(
      'Participants present ' +
        JSON.stringify({
          classId,
          participantCount: joinedParticipants.length,
          currentState: audioContextStateRef.current,
          event: 'participants_present',
        }),
    );

    if (!initRef.current) {
      initRef.current = true;
      const setupTimeout = setTimeout(setupAudio, 1000);
      return () => {
        clearTimeout(setupTimeout);
        if (initRef.current) {
          // Only cleanup on unmount if we're actually initialized
          datadogLogs.logger.info(
            'Cleanup on unmount ' +
              JSON.stringify({
                classId,
                currentState: audioContextStateRef.current,
                participantCount: latestParticipantCountRef.current,
                event: 'cleanup_unmount',
              }),
          );
          cleanupAudioProcessing('STATE_TRANSITION');
          initRef.current = false;
        }
      };
    } else {
      processParticipants(joinedParticipants, classId, audioContextRef, processedParticipantsRef);
    }
  }, [joinedParticipants.length]);
};

export default useStudentTalkTime;
